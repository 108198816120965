import { ObjectHelper } from '~/helpers/ObjectHelper';

export const useListingState = () => {
    const activeFilters = useState<Record<string, any>>('listingState-activeFilters', () => reactive({}));
    const activeSorting = useState<string | null>('listingState-activeSorting', () => ref(null));

    const activeFiltersCount = computed(() =>
        Object.values(activeFilters.value).reduce((totalCount, value) => {
            const items = ObjectHelper.isArray(value) ? value : [value];

            return totalCount + items.length;
        }, 0),
    );

    const setRefinements = (refinements: Record<string, string | string[]>) => {
        if (refinements.sortBy) {
            activeSorting.value = refinements.sortBy as string;
            delete refinements.sortBy;
        } else {
            activeSorting.value = null;
        }

        activeFilters.value = refinements;
    };

    return {
        activeFilters,
        activeSorting,
        activeFiltersCount,
        setRefinements,
    };
};
